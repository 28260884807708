import { FlexRow } from "./Container";
import { Typography } from "@papercup-ai/crane";
import * as React from "react";

export const LoadingPage = (props: any) => (
  <FlexRow
    id="layout"
    background={"bg-primary"}
    dimensions={{ h: "vh-100", w: "vw-100" }}
    flex={{
      fai: "items-center",
      fjc: "justify-center"
    }}
    {...props}
  >
    <Typography.H1 classNameOverride={"slide-in-bottom-keyframe-ef"}>
      Loading...
    </Typography.H1>
  </FlexRow>
);
