export const DEV = process.env.NODE_ENV !== "production";

export const GA_TRACKING_ID = process.env.GA_TRACKING_ID || "";
export const GA_TRACKING_ID_EXPERIMENTAL =
  process.env.GA_TRACKING_ID_EXPERIMENTAL || "";

export const SENTRY_TRACKING_ID =
  "https://4b43edae35f94af4a2d76a9872c22e74@o207841.ingest.sentry.io/1546496";

export const SITE_NAME = "";
export const SITE_TITLE = "";
export const SITE_DESCRIPTION = "";
export const SITE_IMAGE = "";
