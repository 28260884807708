import React, { useEffect, useState, useContext } from "react";
import {
  darkBgColours,
  lightBgColours,
  colours as darkColours,
  lightColours,
  baseColours
} from "../themes/colours";

import { ThemeContext, InterfaceTheme } from "../context/index";

export const useTheme = () => useContext<InterfaceTheme>(ThemeContext);

export function ThemeProvider(props: { children: React.ReactNode }) {
  const [dark, setDark] = useState(false);
  const [colours, setColours] = useState(darkColours);
  const [bgColours, setBgColours] = useState(darkBgColours);

  const applyThemeClass = (theme: string) => {
    const root = document.getElementsByTagName("html")[0];
    root.className = theme;
  };

  // paints the app before it renders elements
  useEffect(() => {
    const isDarkTheme = window.localStorage.getItem("isDarkTheme");

    if (isDarkTheme === "true") {
      setDark(true);
      setColours(darkColours);
      setBgColours(darkBgColours);
      applyThemeClass("light");
    }

    if (!isDarkTheme || isDarkTheme === "false") {
      setDark(false);
      setColours(lightColours);
      setBgColours(lightBgColours);
      applyThemeClass("dark");
    }
    // if state changes, repaints the app
  }, [dark]);

  const toggle = () => {
    setDark(!dark);
    window.localStorage.setItem("isDarkTheme", String(!dark));
  };

  return (
    <ThemeContext.Provider
      value={{
        baseColours,
        isDark: dark,
        toggle,
        colours,
        bgColours
      }}
    >
      {props.children}
    </ThemeContext.Provider>
  );
}
